import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Badge } from 'react-bootstrap';
import { collection, query, where, getDocs } from 'firebase/firestore';
import db from '../firebase';
import UpcomingEvents from '../components/UpcomingEvents';
import PlaceCard from '../components/PlaceCard';
import SendFoxForm from '../components/SendFoxForm';
import { Link } from 'react-router-dom';
import Tags from '../config/Tags';

const MainPage = () => {
    const [places, setPlaces] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const availableTags = Tags

    useEffect(() => {
        const fetchPlaces = async () => {
          let placeQuery = query(collection(db, 'places'), where('status', '==', true)); // Always fetch only active places
          if (selectedTags.length > 0) {
              placeQuery = query(placeQuery, where('tags', 'array-contains-any', selectedTags));
          }
            const querySnapshot = await getDocs(placeQuery);
            const fetchedPlaces = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setPlaces(fetchedPlaces);
        };
        fetchPlaces();
    }, [selectedTags]);

    const toggleTag = (tag) => {
        setSelectedTags(prev => prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]);
    };

    const clearFilters = () => {
        setSelectedTags([]);
    };

    return (
        <Container>
            <Row>

                <Col md={9}>
                <Card style={{ width: '100%', backgroundColor: '#f8f9fa' }}>
            <Card.Header as="h2" style={{ backgroundColor: '#007bff', color: '#ffffff' , fontSize:24}}>Places</Card.Header>
 
                    <div className="mb-3 p-1">
                        {availableTags.map((tag, index) => (
                            <Badge key={index} bg={selectedTags.includes(tag) ? "primary" : "secondary"} onClick={() => toggleTag(tag)} style={{ cursor: 'pointer', marginRight: '5px' }}>
                                {tag}
                            </Badge>
                        ))}
                        <Button variant="link" size="sm" onClick={clearFilters}>Clear Filters</Button>
                    </div>
                    {places.map((place, index) => (
                        <PlaceCard key={index} place={place} />
                    ))}
                    </Card>
                </Col>
                <Col md={3}>                    <Card className='mb-2'>
                        <Card.Body>
                            <Card.Title>Do you have a place to recommend?</Card.Title>
                            <Card.Text>
                                <Button variant='info' as={Link} to={'/add-place'}>Add a Place</Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className='mb-2'>
                        <Card.Body>
                            <Card.Title>Subscribe to our newsletter</Card.Title>
                            <Card.Text>
                            <SendFoxForm />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <UpcomingEvents />
                </Col>
                {/*<Col md={3}>
                    <Card className='mb-4'>
                        <Card.Body>
                            <Card.Title>Do you have a place to recommend?</Card.Title>
                            <Card.Text>
                                <Button variant='info' as={Link} to={'/add-place'}>Add a Place</Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title>Subscribe to our newsletter</Card.Title>
                            <Card.Text>
                            <SendFoxForm />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    
                </Col>*/}
            </Row>
        </Container>
    );
};

export default MainPage;
