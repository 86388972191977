import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';

const ProtectedRoute = ({ children, permission }) => {
  const { currentUser, roles } = useAuth();

  if (!currentUser) {
      // User not logged in
      return <Navigate to="/appmanager" />;
  } else if (permission && !roles.includes(permission)) {
      // User does not have the required permission
      return <Navigate to="/" />;
  }

  return children;
};
export default ProtectedRoute;