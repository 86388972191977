import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Logo from '../images/ShoreLineKid-Logo-Horizontal.png';

function Layout({children}) {
  
  
  return (
    <Container fluid style={{paddingTop: 80}}>


      <Navbar collapseOnSelect expand="lg"  style={{background:"#039be5", paddingLeft:20}}  fixed='top'>
      <Container>
      <Navbar.Brand href="/"><img src={Logo} height="26" width="195"  alt="ShorelineKid"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" onSelect={(a,b) => console.log("select",a,b)}>

          <NavLink to="/"><Nav.Link href="/">Home</Nav.Link></NavLink>
                 {/* <NavLink to="/camps"><Nav.Link href="/camps">Camps</Nav.Link></NavLink>*/}
                  <Nav.Item><a href="mailto:contact@shorelinekid.com" className="nav-link">Contact</a></Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
  </Navbar>
    {children}
    </Container>
  )
    return (
        <Container fluid style={{paddingTop: 80}}>
            <Navbar style={{background:"#039be5", paddingLeft:20}} fixed="top" >
                <Navbar.Brand href="/"><img src={Logo} height="26" width="195"  alt="ShorelineKid"/></Navbar.Brand>
                <Navbar.Toggle aria-controls='navmenu-nav' />
                <Navbar.Collapse id='navmenu-nav'>
                <Nav className="me-auto">


                  <Nav.Item><NavLink to="/" className="nav-link">Home</NavLink></Nav.Item>
                  <Nav.Item><NavLink to="/camps" className="nav-link">Camps</NavLink></Nav.Item>
                  <Nav.Item><a href="mailto:contact@shorelinekid.com" className="nav-link">Contact</a></Nav.Item>
                </Nav>
                </Navbar.Collapse>
            </Navbar>
            {children}
        </Container>
    );
}

export default Layout;