import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAAL-B1bA_DrmQlbCjKoCk7JKFAzumky6Y",
    authDomain: "shorelinekid-4dcc9.firebaseapp.com",
    projectId: "shorelinekid-4dcc9",
    storageBucket: "shorelinekid-4dcc9.appspot.com",
    messagingSenderId: "761374394757",
    appId: "1:761374394757:web:d3fcdf2d5c8940328ddcec",
    measurementId: "G-8DYF7R9QGJ"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  export const analytics = getAnalytics(app);
  export const auth = getAuth(app);
  
    const db = getFirestore(app);

    export default db;