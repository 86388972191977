import Home from './pages/Main';

import Camps from './pages/Camps';
import AddPlace from './pages/AddPlace';
import AddPlaceInternal from './pages/AddPlaceInternal';

const router = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/add-place",
      element: <AddPlace />,
    },
    {
      path: "/add-place-internal",
      element: <AddPlaceInternal />,
    },
    {
      path: "/camps",
      element: <Camps />,
    },
  ]

export default router;