import "./styles/App.scss";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import Layout from './layout/Layout';
import { Route, Routes } from "react-router-dom";

import { AuthProvider } from './auth/AuthProvider'; // Assuming you have auth folder


import RoutesArray from './routes';
import ProtectedRoute from "./components/ProtectedRoute";

import Dashboard from "./pages/appmanager/AdminDashboard";
import AdminLogin from "./pages/appmanager/AdminLogin";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAAL-B1bA_DrmQlbCjKoCk7JKFAzumky6Y",
  authDomain: "shorelinekid-4dcc9.firebaseapp.com",
  projectId: "shorelinekid-4dcc9",
  storageBucket: "shorelinekid-4dcc9.appspot.com",
  messagingSenderId: "761374394757",
  appId: "1:761374394757:web:d3fcdf2d5c8940328ddcec",
  measurementId: "G-8DYF7R9QGJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);



function App() {
  return (
    <AuthProvider>
    <Layout>
      <Routes>
        {RoutesArray.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.element}
          />
        ))}
        <Route path="/appmanager" element={<AdminLogin /> } />
        <Route path="/dashboard" element={
         <ProtectedRoute permission={"placeEdit"}>
            <Dashboard />
         </ProtectedRoute>       
        } />

       
      </Routes>
    </Layout>
    </AuthProvider>
  )
  
    
  


  
}



export default App;
