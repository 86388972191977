import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Row, Col, ListGroup } from 'react-bootstrap';
import { debounce } from 'lodash';
import { addDoc, collection, query, where, getDocs } from "firebase/firestore"; 
import availableTags from '../config/Tags';

import db from '../firebase';



const AddPlace = () => {
    const [step, setStep] = useState(1);
    const [placeName, setPlaceName] = useState('');
    const [website, setWebsite] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [yourEmail, setYourEmail] = useState('');
    const [address, setAddress] = useState('');
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');
    const [placeEmail, setPlaceEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [tags, setTags] = useState([]);
    const [matchingNames, setMatchingNames] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const search = debounce(async (name) => {
            if (name.length > 2) {
                const searchName = name.toLowerCase();
                const q = query(collection(db, 'places'), where('placeName_lowerCase', '>=', searchName), where('placeName_lowerCase', '<=', searchName + '\uf8ff'));
                const querySnapshot = await getDocs(q);
                const names = [];
                querySnapshot.forEach(doc => {
                    names.push(doc.data().placeName);
                });
                setMatchingNames(names);
            } else {
                setMatchingNames([]);
            }
        }, 300);

        if (placeName) {
            search(placeName);
        }

        return () => search.cancel();
    }, [placeName]);

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const toggleTagSelection = (tag) => {
        setTags(prev => {
            if (prev.includes(tag)) {
                return prev.filter(t => t !== tag);
            } else {
                return [...prev, tag];
            }
        });
    };

    const handleAddPlace = async () => {
        const placeData = {
            placeName,
            website,
            city,
            state,
            yourEmail,
            address,
            zip,
            phone,
            placeEmail,
            notes,
            tags,
            placeName_lowerCase: placeName.toLowerCase()
        };
        await addDoc(collection(db, 'places'), placeData);
        setIsSubmitted(true);
    };

    if (isSubmitted) {
        return (
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <Card>
                        <Card.Body>
                            <h3>Thank You!</h3>
                            <p>We will review the place and make it available for searching after it is reviewed.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            <Col md={{ span: 6, offset: 3 }}>
                
                        <Form onSubmit={(e) => { e.preventDefault(); handleAddPlace(); }}>
                            {step === 1 ? (
                                <>
                                    <Form.Group controlId="placeName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={placeName}
                                            onChange={(e) => setPlaceName(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <ListGroup>
                                        {matchingNames.map((matchName, index) => (
                                            <ListGroup.Item key={index} onClick={() => alert("It looks like we already have that place. Is there something else we should know about it? Please send us a note using the contact form.")}>
                                                {matchName}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    <Button variant="primary" onClick={handleNextStep}>
                                        Next
                                    </Button>
                                </>
                            ) : step === 2 ? (
                                <>
                                    <Card className='mb-2'>
                                        <Card.Body>
                                    <Form.Group controlId="website">
                                        <Form.Label>*Website</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={website}
                                            onChange={(e) => setWebsite(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="phone">
                                        <Form.Label>Place Contact Phone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="placeEmail">
                                        <Form.Label>Place Contact Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            value={placeEmail}
                                            onChange={(e) => setPlaceEmail(e.target.value)}
                                        />
                                    </Form.Group>
                                    </Card.Body>
                                    </Card>

                                    <Card className='mb-2'>
                                        <Card.Body>
                                    <Form.Group controlId="address">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="city">
                                        <Form.Label>*City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="state">
                                        <Form.Label>*State</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="zip">
                                        <Form.Label>Zip</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={zip}
                                            onChange={(e) => setZip(e.target.value)}
                                        />
                                    </Form.Group>
                                    </Card.Body>
                                    </Card>

                                    <Card className='mb-2'>
                                        <Card.Body>

                                    <Form.Group controlId="yourEmail">
                                        <Form.Label>Your Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            value={yourEmail}
                                            onChange={(e) => setYourEmail(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    </Card.Body>
                                    </Card>
 

                                    <Button variant="primary" onClick={handlePreviousStep}>
                                        Previous
                                    </Button>
                                    <Button variant="primary" onClick={handleNextStep}>
                                        Next
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <h4>Select Tags</h4>
                                    <div className="mb-3">
                                        {availableTags.map((tag, index) => (
                                            <Button
                                                key={index}
                                                variant={tags.includes(tag) ? "primary" : "secondary"}
                                                onClick={() => toggleTagSelection(tag)}
                                                className="m-1"
                                            >
                                                {tag}
                                            </Button>
                                        ))}
                                    </div>
                                    <Form.Group controlId="notes">
                                        <Form.Label>Notes</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" onClick={handlePreviousStep}>
                                        Previous
                                    </Button>
                                    <Button variant="primary" onClick={handleAddPlace}>
                                        Add Place
                                    </Button>
                                </>
                            )}
                        </Form>
         
            </Col>
        </Row>
    );
};

export default AddPlace;
