import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const SendFoxForm = () => {
    useEffect(() => {
        // Function to dynamically load the SendFox script
        const loadScript = () => {
            const script = document.createElement('script');
            script.src = "https://sendfox.com/js/form.js";
            script.async = true;
            
            document.body.appendChild(script);
        };

        // Load the script when the component mounts
        console.log("loadscript",loadScript());

        // Optional: Clean up the script when the component unmounts
        return () => {
            const script = document.querySelector('script[src="https://sendfox.com/js/form.js"]');
            if (script) {
                script.remove();
            }
        };
    }, []);

    return (
        <Form method="post" action="https://sendfox.com/form/1drnn5/1r2kex" className="sendfox-form" id="1r2kex" data-async="true" data-recaptcha="true">
            <Form.Group controlId="sendfox_form_name">
                {/*<Form.Label>First Name:</Form.Label>*/}
                <Form.Control type="text" placeholder="First Name" name="first_name" id="sendfox_form_name" required />
            </Form.Group>
            <Form.Group controlId="sendfox_form_email">
                {/*<Form.Label>Email:</Form.Label>*/}
                <Form.Control type="email" placeholder="Email" name="email" id="sendfox_form_email" required />
            </Form.Group>
            <Form.Group controlId="gdpr">
                <Form.Check type="checkbox" name="gdpr" label="I agree to receive email updates and promotions." required />
            </Form.Group>
            {/* Hidden field for bot protection */}
            <div style={{ position: "absolute", left: "-5000px", ariaHidden: "true" }}>
                <Form.Control type="text" name="a_password" tabIndex="-1" value="" autoComplete="off" />
            </div>
            <Button variant="primary" block type="submit" style={{ width: "100%" }}>Signup for News</Button>
        </Form>
    );
};

export default SendFoxForm;
