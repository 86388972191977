import React, { useState, useEffect } from 'react';
import db from '../firebase';
import {  collection, query, /*orderBy, limit, startAfter,*/ getDocs, where } from 'firebase/firestore';



//import Pagination from '../components/Pagination';
import PlaceCard from '../components/PlaceCard';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

const CampsList = () => {
   // const [currentPage, setCurrentPage] = useState(1);
    const [camps, setCamps] = useState([]);
   // const [hasMore, setHasMore] = useState(true);
  
    useEffect(() => {
      const fetchCamps = async () => {
        //const pageSize = 20; // Set the desired number of camps per page
        const campRef = collection(db, 'camps'); // Reference your camps collection
  
        // Create a query with pagination logic
        const campQuery = query(
          campRef,
          where('status', '==', true), // Only fetch active camps
          //orderBy('name'), // You can change the ordering here if needed
          //startAfter(currentPage === 1 ? null : camps[camps.length - 1]), // Start after the last camp of the previous page
          //limit(pageSize)
        );
  
        const querySnapshot = await getDocs(campQuery);

        const fetchedCamps = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(), // Extract camp data from each document
        }));

        setCamps([...camps, ...fetchedCamps]); // Concatenate new camps to existing ones
        //setHasMore(querySnapshot.size === pageSize); // Check if there are more camps to fetch
      };
  
      fetchCamps();
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
  
    //const handlePageChange = (pageNumber) => {
      //setCurrentPage(pageNumber);
    //};
  
    return (
      <Container>
        <Row><Col><h1>Camps</h1></Col></Row>
        <Row>
            <Col md={8}>
                {camps.map((camp,index) => (
                    <PlaceCard key={index} camp={camp} />
                ))}
            </Col>
            <Col md={4}>
                  <Card className='mb-4'>
                    <Card.Body>
                      <Card.Title>Do you have a place to recommend?</Card.Title>
                      <Card.Text>
                        <Button variant='info'>Add to List</Button>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title>Fun for Shoreline Families!</Card.Title>
                      <Card.Text>
                        
                        <p>Discover the best camps, activities, and hidden gems for kids near the CT shoreline! ☀️ Right now, explore our **diverse camp listings** for every age, interest, and budget.</p>
                        <p>**We're expanding our horizons!** Expect even more resources for Shoreline families, launching soon.</p>
                        <p>Bookmark Shoreline Kid and join the fun! Got suggestions? <a href="mailto:contact@shorelinekid.com">Contact us!</a></p>
                            
                      </Card.Text>
                    </Card.Body>
                  </Card>
            </Col>
        </Row>
        <ul>

        </ul>

      </Container>
    );
  };
  
  export default CampsList;