import React, { useState, useEffect } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import db from '../firebase';
import moment from 'moment';

const UpcomingEvents = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            //const today = new Date().toISOString().split('T')[0]; // Format today's date as YYYY-MM-DD
            const today = moment().startOf('day').toDate(); // Format today's date as YYYY-MM-DD    
            const eventsRef = collection(db, 'events'); // Reference your events collection
           
            const eventsQuery = query(
                eventsRef,
                where('startDate', '>=', today), // Fetch events from today onwards
                orderBy('startDate') // Ensure the events are ordered by date
            );
            
            try {
                const querySnapshot = await getDocs(eventsQuery);
                const fetchedEvents = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                console.log("fetchEvents", fetchedEvents)
                setEvents(fetchedEvents);
            } catch (error) {
                console.error("Failed to fetch events:", error);
            }
        };

        fetchEvents();
    }, []);

    return (
        <Card style={{ width: '100%', backgroundColor: '#f8f9fa' }}>
            <Card.Header as="h2" style={{ backgroundColor: '#007bff', color: '#ffffff' , fontSize:24}}>Upcoming Events & Activities</Card.Header>
            <ListGroup variant="flush">
                {events.length > 0 ? (
                    events.map((event, index) => (
                        <ListGroup.Item key={index} style={{ padding: '20px', borderBottom: '1px solid #dee2e6' }}>
                            <h3 style={{ color: '#007bff',fontSize:20 }}>{event.name}</h3>
                            <p><strong>Date{event.endDate > event.startDate ? "s" : ""}:<br /></strong> <span style={{ fontStyle: 'italic' }}>{moment(event.startDate.toDate()).format("M/D/YYYY")}{event.endDate > event.startDate ? `-${moment(event.endDate.toDate()).format("M/D/YYYY")}` : ""}</span></p>
                            <p><strong>Location:</strong><br /><span>{event.location}<br />{event.city}, {event.state}</span></p>
                            {event.description && <p style={{ color: '#6c757d' }}>{event.description}</p>}
                            {event.fee && <p><strong>Fee:</strong><br /><span>{event.fee}</span></p>}
                            {event.ages && <p><strong>Ages:</strong><br /><span>{event.ages}</span></p>}
                            {event.url && <a href={event.url}>Website</a>}
                        </ListGroup.Item>
                    ))
                ) : (
                    <ListGroup.Item style={{ padding: '20px' }}>No upcoming events</ListGroup.Item>
                )}
            </ListGroup>
        </Card>
    );
};

export default UpcomingEvents;
