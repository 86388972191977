import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import db from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
// Create a context
const AuthContext = createContext();

// Auth provider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [roles,setRoles] = useState([]);

  useEffect(() => {
      const auth = getAuth();
      

      const unsubscribe = onAuthStateChanged(auth, async (user) => {
          setCurrentUser(user);
          if (user) {
              const userRef = doc(db, "users", user.uid);
              const userDoc = await getDoc(userRef);
              setRoles(userDoc.data().roles);

              if (!userDoc.exists()) {
                  // User does not exist in Firestore, create a new user record
                  await setDoc(userRef, {
                      email: user.email,
                      createdAt: new Date(),
                      roles: [], // Additional fields such as roles can be added here
                      // More fields can be initialized here as needed
                  });
                  
              }


          }
          setLoading(false);
      });

      return () => unsubscribe(); // Clean up the subscription
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, loading,roles }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Hook to use auth context
export const useAuth = () => useContext(AuthContext);
