import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import db from '../firebase';
import { addDoc, collection } from "firebase/firestore"; 
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import availableTags from '../config/Tags';

const storage = getStorage();

const stateDefaults = {
    placeName: '',
    website: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    placeEmail: '',
    tags: [],
    weeklyCapacity: '',
    minimumAge: '',
    maximumAge: '',
    minimumGrade: '',
    maximumGrade: '',
    pricing: '',
    description: '',

    logoImage: null,
    photos: [],
    pdfBrochure: null,
    status: true
}

const AddPlace = () => {
  const [placeData, setPlaceData] = useState(stateDefaults);

  // ... existing handleInputChange handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlaceData({ ...placeData, [name]: value });
};

  const handleLogoImageChange = (e) => {
    const file = e.target.files[0];
    setPlaceData({ ...placeData, logoImage: file });
  };

  const handlePhotosChange = (e) => {
    const files = Array.from(e.target.files);
    setPlaceData({ ...placeData, photos: files });
  };

  const handlePdfBrochureChange = (e) => {
    const file = e.target.files[0];
    setPlaceData({ ...placeData, pdfBrochure: file });
  };

  const toggleTagSelection = (tag) => {
    setPlaceData(prev => {
        if (prev.tags.includes(tag)) {
            return { ...prev, tags: prev.tags.filter(t => t !== tag) }; 
            
        } else {
            return {...prev, tags: [...prev.tags, tag]};
        }
    });
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare data for Firestore
    const updatedPlaceData = { ...placeData };

    // Handle logo image if selected
    if (placeData.logoImage) {
      const logoRef = ref(storage, `logos/${placeData.placeName.replace(/[\W_]+/g, '_')}-${Date.now()}.jpg`); // Create unique filename
      await uploadBytes(logoRef, placeData.logoImage);
      const logoUrl = await getDownloadURL(logoRef);
      updatedPlaceData.logoImageUrl = logoUrl;
    }

    // Handle photos if selected
    updatedPlaceData.photos = [];
    for (const photo of placeData.photos) {
      const photoRef = ref(storage, `photos/${placeData.placeName.replace(/[\W_]+/g, '_')}-${Date.now()}-${photo.name}`);
      await uploadBytes(photoRef, photo);
      const photoUrl = await getDownloadURL(photoRef);
      updatedPlaceData.photos.push(photoUrl);
    }

    // Handle PDF brochure if selected
    if (placeData.pdfBrochure) {
      const brochureRef = ref(storage, `brochures/${placeData.placeName.replace(/[\W_]+/g, '_')}-${Date.now()}.pdf`);
      await uploadBytes(brochureRef, placeData.pdfBrochure);
      const brochureUrl = await getDownloadURL(brochureRef);
      updatedPlaceData.pdfBrochureUrl = brochureUrl;
    }

    delete updatedPlaceData.logoImage; 
    delete updatedPlaceData.pdfBrochure; 
  

    // Add camp data to Firestore with updated URLs
    try {
      await addDoc(collection(db, 'places'), updatedPlaceData);

      // Reset form fields
      setPlaceData(stateDefaults);

      alert('Place added successfully!');
    } catch (error) {
      console.error('Error adding place:', error);
      alert('An error occurred while adding the place. Please try again later.');
    }
  };

  // ... rest of the component

  return (
    <Form onSubmit={handleSubmit}>
    <Form.Group controlId="placeName">
        <Form.Label>Place Name *</Form.Label>
        <Form.Control
            type="text"
            name="placeName"
            value={placeData.placeName}
            onChange={handleInputChange}
            required
        />
    </Form.Group>
    <Form.Group controlId="website">
        <Form.Label>Place Website *</Form.Label>
        <Form.Control
            type="text"
            name="website"
            value={placeData.website}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="contactName">
        <Form.Label>Contact Name *</Form.Label>
        <Form.Control
            type="text"
            name="contactName"
            value={placeData.contactName}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="contactEmail">
        <Form.Label>Contact Email *</Form.Label>
        <Form.Control
            type="email"
            name="contactEmail"
            value={placeData.contactEmail}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="contactPhone">
        <Form.Label>Contact Phone *</Form.Label>
        <Form.Control
            type="text"
            name="contactPhone"
            value={placeData.contactPhone}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="address">
        <Form.Label>Address *</Form.Label>
        <Form.Control
            type="text"
            name="address"
            value={placeData.address}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="city">
        <Form.Label>City *</Form.Label>
        <Form.Control
            type="text"
            name="city"
            value={placeData.city}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="state">
        <Form.Label>State *</Form.Label>
        <Form.Control
            type="text"
            name="state"
            value={placeData.state}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="zip">
        <Form.Label>Zip *</Form.Label>
        <Form.Control
            type="text"
            name="zip"
            value={placeData.zip}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="phone">
        <Form.Label>Phone *</Form.Label>
        <Form.Control
            type="text"
            name="phone"
            value={placeData.phone}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="placeEmail">
        <Form.Label>Place Info Email *</Form.Label>
        <Form.Control
            type="email"
            name="placeEmail"
            value={placeData.placeEmail}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>

    <Form.Group controlId="weeklyCapacity">
        <Form.Label>Weekly Capacity *</Form.Label>
        <Form.Control
            type="text"
            name="weeklyCapacity"
            value={placeData.weeklyCapacity}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="minimumAge">
        <Form.Label>Minimum Age *</Form.Label>
        <Form.Control
            type="text"
            name="minimumAge"
            value={placeData.minimumAge}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="maximumAge">
        <Form.Label>Maximum Age *</Form.Label>
        <Form.Control
            type="text"
            name="maximumAge"
            value={placeData.maximumAge}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>    <Form.Group controlId="minimumGrade">
        <Form.Label>Minimum Grade *</Form.Label>
        <Form.Control
            type="text"
            name="minimumGrade"
            value={placeData.minimumGrade}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="maximumGrade">
        <Form.Label>Maximum Grade *</Form.Label>
        <Form.Control
            type="text"
            name="maximumGrade"
            value={placeData.maximumGrade}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="pricing">
        <Form.Label>Pricing *</Form.Label>
        <Form.Control
            type="text"
            name="pricing"
            value={placeData.pricing}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>
    <Form.Group controlId="campDescription">
        <Form.Label>Place Description *</Form.Label>
        <Form.Control
            as="textarea"
            name="description"
            value={placeData.description}
            onChange={handleInputChange}
            //required
        />
    </Form.Group>

    <Form.Group controlId="logoImage">
        <Form.Label>Logo Image (png, gif, jpg)</Form.Label>
        <Form.Control
            type="file"
            name="logoImage"
            onChange={handleLogoImageChange}
        />
    </Form.Group>
    <Form.Group controlId="photos">
        <Form.Label>Photos (up to 1024x768 Gif, PNG, JPG)</Form.Label>
        <Form.Control
            type="file"
            name="photos"
            multiple
            onChange={handlePhotosChange}
        />
    </Form.Group>
    <Form.Group controlId="pdfBrochure">
        <Form.Label>PDF Brochure</Form.Label>
        <Form.Control
            type="file"
            name="pdfBrochure"
            onChange={handlePdfBrochureChange}
        />
    </Form.Group>

    <h4>Select Tags</h4>
    <div className="mb-3">
        {availableTags.map((tag, index) => (
            <Button
                key={index}
                variant={placeData.tags.includes(tag) ? "primary" : "secondary"}
                onClick={() => toggleTagSelection(tag)}
                className="m-1"
            >
                {tag}
            </Button>
        ))}
    </div>

    <Button variant="primary" type="submit">
        Save
    </Button>
</Form>
  );
};

export default AddPlace;
