import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';

const PlaceCard = ({ place }) => {
   
  return (
    <Card className='p-2 mb-4'>
      
      <Card.Body>
        <Row className='mb-4'>
            {place?.logoImageUrl && <Col md={3}>
                <Card.Img src={place.logoImageUrl || 'placeholder.jpg'} alt={place.name} style={{maxWidth: 100,float:"left",marginRight:30}} />
            </Col>}
            <Col md={9}>
                <Card.Title>{place.placeName}</Card.Title>
                {place.tags?.map((tag, index) => (
                    <Badge bg="secondary" className="me-1">{tag}</Badge>
                ))}
                {place.address && <Card.Text style={{marginBottom:0}}>{place.address}</Card.Text>}
                {(place.city || place.state) && <Card.Text>{place.city}{place.city && place.state && ", "}{place.state}</Card.Text>}

                {place.website && <Card.Link href={place.website}>Website</Card.Link>}
                {place.placeInfoEmail && <Card.Link href={`mailto:${place.placeInfoEmail}`}>Contact</Card.Link>}
            </Col>
        </Row>
        {place.description && <Card.Text className='mb-4'><strong>Description:</strong> {place.description}</Card.Text>}
        {place.placeFeedback && <Card.Text><strong>Feedback:</strong> {place.placeFeedback}</Card.Text>}
        
       
      </Card.Body>
    </Card>
  );
};

export default PlaceCard;